@import '@dx-ui/osc-styles/base.css';
@import '@dx-ui/osc-styles/components.css';
@import '@dx-ui/osc-styles/utilities.css';
@import '@dx-ui/osc-styles/themes/theme-hi.css';

html,
body {
  height: 100%;
}

*,
*:before,
*:after {
  font-family: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
label,
ul {
  margin: 0;
  padding: 0;
}

a,
button {
  cursor: pointer;
}

img {
  max-width: 100%;
}

header > div {
  z-index: 9 !important;
}

@keyframes container-rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  to {
    transform: rotate(1080deg);
  }
}

@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(130deg);
  }
}

@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }

  to {
    transform: rotate(-130deg);
  }
}

@-webkit-keyframes autoFill {
  to {
    background: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-animation-name: autoFill;
  -webkit-animation-fill-mode: both;
}

[data-reach-menu-popover] {
  z-index: 20;
}

.leading-11 {
  line-height: 2.75rem !important;
}

.box-shadow-inset {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.description-container > p {
  margin: 1rem 0px;
}

.description-container > ul,
.description-container > ol {
  padding-left: 2rem;
  margin: 0.5rem 0px;
}

.inline-loader {
  margin: 0 auto;
  animation: container-rotate 1568ms linear infinite;
}

.inline-loader:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 4px solid #d2d0d0;
  border-radius: 50%;
}

.inline-loader .spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: theme(colors.hilton-alt);
}

.inline-loader .spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: theme(colors.hilton-alt);
}

.inline-loader .active .spinner-layer {
  opacity: 1;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.inline-loader .gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.inline-loader .gap-patch .circle {
  width: 1000%;
  left: -450%;
}

.inline-loader .circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.inline-loader .circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 4px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  -webkit-animation: none;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.inline-loader .circle-clipper.left .circle {
  left: 0;
  border-right-color: transparent !important;
  transform: rotate(129deg);
}

.inline-loader .circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  transform: rotate(-129deg);
}

.inline-loader .active .circle-clipper.left .circle {
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.inline-loader .active .circle-clipper.right .circle {
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.public-DraftStyleDefault-ul,
.description-container ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.public-DraftStyleDefault-ol,
.description-container ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.empty-image {
  max-width: 19.0625rem;
  min-height: 11.625rem;
  margin-bottom: 1rem;
}

.-leading-0\.2px {
  letter-spacing: -0.2px;
}

.tracking-0\.6px {
  letter-spacing: 0.6px;
}

.text-larger {
  font-size: 1.375rem;
}

.text-color-gray {
  color: #444444;
}

.text-13px {
  font-size: 13.33px;
}

.border-medium-light {
  border-color: #949494;
}

.text-medium-light {
  color: #949494;
}

.bg-light-blue {
  background-color: theme(colors.bg-primary);
}

.login-iframe {
  border: 0;
  border-bottom: thin solid #e8e8e8;
  min-height: 27.5rem;
  max-width: 30rem;
  min-width: 20.3125rem;
  visibility: visible;
}

.join-honors-link > svg {
  display: inline-block;
  margin-left: 0.5rem;
}

.join-honors-link #Group {
  stroke: theme(colors.hilton);
}

.selected-option::after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #1d1d1d;
  content: '';
  display: inline-block;
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: calc(50% - 4px);
  margin-right: -15px;
}

.selected-option::before {
  background: theme(colors.bg);
  bottom: 3px;
  content: '';
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 3px;
  width: 40px;
  margin-right: -15px;
}

.card-exp-date-wrapper > div:first-of-type {
  margin-right: 1rem;
}

.card-exp-date-wrapper > div:last-of-type {
  margin-left: 1rem;
}

.step-tracker-progress {
  background: #aaaaaa;
  position: relative;
  height: 3px;
}

.step-tracker-progress::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  height: 3px;
  width: 100%;
  max-width: var(--progress-width);
}

.step-tracker-steps {
  color: #1d1d1d;
}

.heading-icon path {
  fill: #1d1d1d;
  stroke: #1d1d1d;
}

.btn-delete-img:hover,
.btn-delete-img:focus,
.btn-delete-img:active {
  outline: 1px solid theme(colors.highlight);
  box-shadow: 0 0 1px 2px theme(colors.highlight);
}

div.dialog-content > div:nth-child(2) > div:nth-child(2) {
  display: none;
}

.event-info-container > div:first-of-type,
.event-info-container > div:last-of-type {
  border: none;
  padding: 0;
}

.event-info-container > div > p > span {
  min-width: 9.375rem;
}

.rejected-images-ul {
  overflow-y: scroll;
  height: 200px;
}

.rejected-images-ul::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.text-strong-inline strong {
  display: inline-block;
}

.text-strong strong {
  font-weight: 700;
}

.tooltip {
  display: none;
  background-color: theme(colors.bg);
  position: absolute;
  z-index: 99;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  width: max-content;
  max-width: calc(100vw - 32px);
  padding: 0.5rem;
  border-color: #949494;
  border-width: 1px;
  border-style: solid;
  font-weight: 400;
  font-size: 0.875rem;
  text-align: left;
  white-space: normal;
  color: #1d1d1d;
  cursor: default;
}

.tooltip::before,
.tooltip::after {
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom-width: 10px;
  border-bottom-style: solid;
  transform: translateX(-50%) rotate(180deg);
}

.tooltip::after {
  bottom: -9px;
  border-bottom-color: theme(colors.text-inverse);
}

.tooltip::before {
  bottom: -10px;
  border-bottom-color: #949494;
}

.btn-close {
  right: 18px;
  top: 15px;
}

.btn-close:focus,
.btn-close:hover,
.btn-close:active {
  outline: 1px solid theme(colors.hilton);
}

.hotel-info-wrapper ~ div:not(:first-of-type) {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: thin solid theme(colors.border-alt);
}

.hotel-info-details {
  border-top: none;
}

.hotel-info-details ~ div:not(.resort-fee-desc) {
  padding: 1rem 0;
  border-top: thin solid theme(colors.border-alt);
}

.bg-img-headline {
  width: 1.5625rem;
  background-image: url('/dx_hiltonlink_app/static/ic-headline.svg');
}

.bg-img-close {
  width: 1.5625rem;
  background-image: url('/dx_hiltonlink_app/static/close-blue.svg');
}

.bg-img-images {
  background-image: url('/dx_hiltonlink_app/static/ic-images.svg');
}

.bg-img-fb {
  background-image: url('/dx_hiltonlink_app/static/ic-facebook.svg');
}

.bg-img-email {
  background-image: url('/dx_hiltonlink_app/static/ic-e-mail.svg');
}

.DraftEditor-root {
  @apply text-text;
  font-size: 0.875rem;
  margin: 0.75rem;
  word-break: break-word;
}

.single-line .DraftEditor-root {
  margin: 0.625rem 0 0;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #1d1d1d;
}

.single-line .border-3 .DraftEditor-root {
  margin: -0.25rem 0 0;
}

.single-line .public-DraftEditor-content {
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap !important;
}

.DraftEditor-root #description {
  min-height: 25rem;
  line-height: 1.5;
}

.DraftEditor-root ul,
.DraftEditor-root ol {
  margin: 1rem 0;
  padding: 0 0 0 2rem;
}

.DraftEditor-root #description > div > div {
  margin-bottom: 1rem;
}

.RichEditor-controls .stroke {
  stroke: #1d1d1d;
  fill: transparent;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.RichEditor-controls .fill {
  fill: #1d1d1d;
}

.RichEditor-controls .fill.active,
.RichEditor-controls button:hover {
  fill: theme(colors.hilton);
}

.RichEditor-controls .stroke.active,
.RichEditor-controls button:hover {
  stroke: theme(colors.hilton);
}

.RichEditor-controls .stroke.thin {
  stroke-width: 1;
}

.contact-info-heading-icon {
  margin-left: -35px;
  margin-right: 12px;
  font-size: 1.25rem;
  color: #444444;
}

.contact-info-heading-icon svg > g {
  fill: #444444;
}

.error-page-heading,
.error-page-subheading {
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.hotel-info-inner-wrapper {
  flex-direction: column;
}

.horizontal-bar:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  width: 100%;
  max-width: 100%;
}

.hotel-info-phn path:last-of-type {
  stroke: theme(colors.hilton);
}

.flash-message {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem 0;
  font-weight: 700;
}
.flash-message > span {
  display: flex;
  line-height: 1.5;
  align-items: center;
}

.flash-message > span > span > a,
.flash-message > span > span > button {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
}

.flash-message > span:before {
  display: inline-block;
  content: '';
  background-size: contain;
  flex: 1 0 auto;
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
}

.flash-message-error {
  color: theme(colors.danger);
  background-color: theme(colors.danger-alt);
}
.flash-message-error > span:before {
  background: url(/dx_hiltonlink_app/static/ic-warning.svg) 0% 0% / contain;
}

.flash-message-warning {
  color: inherit;
  background-color: theme(colors.warn-alt);
}
.flash-message-warning > span:before {
  background: url(/dx_hiltonlink_app/static/warning-yellow.svg) 0% 0% / contain;
}

.flash-message-info {
  color: inherit;
  background-color: theme(colors.bg-primary);
}
.flash-message-info > span:before {
  background: url(/dx_hiltonlink_app/static/ic-info.svg) 0% 0% / contain;
}

.flash-message-plain {
  color: inherit;
  background-color: #f4f4f4;
}
.flash-message-plain > span:before {
  background: url(/dx_hiltonlink_app/static/ic-success.svg) 0% 0% / contain;
}

.flash-message-no-bg {
  background-color: transparent;
}

.flash-message-no-bg > span {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.flash-message-no-bg > span > span > button {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.focus-primary:focus {
  outline: 1px solid theme(colors.hilton);
  box-shadow: 0 0 1px 1px theme(colors.hilton);
}

.focus-seconday:focus {
  outline: 1px solid theme(colors.highlight);
  box-shadow: 0 0 1px 1px theme(colors.highlight);
}

.focus {
  outline: 1px solid theme(colors.highlight);
  box-shadow: 0 0 1px 2px theme(colors.highlight);
}

.focus:-moz-focusring {
  outline: 1px solid theme(colors.highlight);
  box-shadow: 0 0 1px 2px theme(colors.highlight);
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .focus {
    outline: 1px solid theme(colors.highlight);
    box-shadow: 0 0 1px 2px theme(colors.highlight);
  }
}

@media screen and (min-width: 768px) {
  .error-page-heading {
    font-size: 5rem !important;
  }

  .flash-message {
    margin: 0.5rem 0;
  }

  .md\:mr-60px {
    margin-right: 60px;
  }

  .step-tracker-steps {
    color: theme(colors.text-alt);
  }

  .single-line .DraftEditor-root {
    margin: 1.25rem 0 0;
  }
}

@media (min-width: 992px) {
  .hotel-info-inner-wrapper {
    margin-right: 44px;
    flex-direction: row;
  }

  .empty-image {
    max-width: 16.9375rem;
    max-height: 11.625rem;
    margin-right: 2rem;
    margin-bottom: 0;
  }
}
