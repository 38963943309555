/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

@layer base {
  :root {
    --transparent: 0 0 0 / 0;
    --color-overlay: 29 29 29 / 30%;
    --color-transparent: rgb(0 0 0 / 0);
    --color-white: #ffffff;
    --color-black: #000000;
    --color-neutral-50: #fafafa;
    --color-neutral-100: #f5f5f5;
    --color-neutral-200: #e5e5e5;
    --color-neutral-300: #d4d4d4;
    --color-neutral-400: #a3a3a3;
    --color-neutral-500: #737373;
    --color-neutral-600: #525252;
    --color-neutral-700: #404040;
    --color-neutral-800: #262626;
    --color-neutral-900: #171717;
    --color-neutral-950: #0a0a0a;

    --font-sans: 'Proxima Nova';
    --font-serif: 'Georgia';
    --font-mono: 'Menlo';
    --font-headline: 'Loew';
    --font-headline-brand: 'Simple Joys';

    /* Body font styles */
    --ds-body-font-family: 'Noto Sans';
    --ds-body-font-weight: 400;
    --ds-body-bold-font-weight: 700;
    --ds-body-letter-spacing: 'normal';
    --ds-body-line-height: 1.5;

    /* Headline font styles */
    --ds-headline-font-family: 'Noto Sans';
    --ds-headline-font-weight: 400;
    --ds-headline-letter-spacing: 'normal';
    --ds-headline-line-height: 1.3;

    /* Subheadline font styles */
    --ds-subheadline-font-family: 'Noto Sans';
    --ds-subheadline-font-weight: 400;
    --ds-subheadline-letter-spacing: 'normal';
    --ds-subheadline-line-height: 1.3;

    --ds-type-scale-01: 12px;
    --ds-type-scale-02: 14px;
    --ds-type-scale-03: 16px;
    --ds-type-scale-04: 18px;
    --ds-type-scale-03: 16px;
    --ds-type-scale-04: 18px;
    --ds-type-scale-05: 20px;
    --ds-type-scale-06: 24px;
    --ds-type-scale-07: 30px;
    --ds-type-scale-08: 36px;
    --ds-type-scale-09: 48px;
    --ds-type-scale-10: 60px;
    --ds-type-scale-11: 72px;
    --ds-type-scale-12: 96px;

    /* Base font-size scale */
    --ds-font-size-base: 16px;
    --ds-font-size-xs: calc((var(--ds-type-scale-01) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-sm: calc((var(--ds-type-scale-02) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-md: calc((var(--ds-type-scale-03) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-lg: calc((var(--ds-type-scale-04) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-xl: calc((var(--ds-type-scale-05) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-2xl: calc((var(--ds-type-scale-06) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-3xl: calc((var(--ds-type-scale-07) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-4xl: calc((var(--ds-type-scale-08) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-5xl: calc((var(--ds-type-scale-09) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-6xl: calc((var(--ds-type-scale-10) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-7xl: calc((var(--ds-type-scale-11) / var(--ds-font-size-base)) * 1rem);
    --ds-font-size-8xl: calc((var(--ds-type-scale-12) / var(--ds-font-size-base)) * 1rem);

    /* Body font-size scale */
    --ds-font-size-body-xs: var(--ds-font-size-xs);
    --ds-font-size-body-sm: var(--ds-font-size-sm);
    --ds-font-size-body-base: var(--ds-font-size-md);
    --ds-font-size-body-lg: var(--ds-font-size-lg);

    /* Heading font-sizes for smaller devices */
    --ds-font-size-heading-sm: var(--ds-font-size-md);
    --ds-font-size-heading-md: var(--ds-font-size-lg);
    --ds-font-size-heading-lg: var(--ds-font-size-xl);
    --ds-font-size-heading-xl: var(--ds-font-size-2xl);
    --ds-font-size-heading-2xl: var(--ds-font-size-3xl);
    --ds-font-size-heading-3xl: var(--ds-font-size-4xl);
  }

  @screen md {
    :root {
      --ds-font-size-heading-sm: var(--ds-font-size-lg);
      --ds-font-size-heading-md: var(--ds-font-size-xl);
      --ds-font-size-heading-lg: var(--ds-font-size-2xl);
      --ds-font-size-heading-xl: var(--ds-font-size-3xl);
      --ds-font-size-heading-2xl: var(--ds-font-size-4xl);
      --ds-font-size-heading-3xl: var(--ds-font-size-5xl);
    }
  }

  /* Heading font-sizes used for larger devices */
  @screen lg {
    :root {
      --ds-font-size-heading-sm: var(--ds-font-size-xl);
      --ds-font-size-heading-md: var(--ds-font-size-2xl);
      --ds-font-size-heading-lg: var(--ds-font-size-3xl);
      --ds-font-size-heading-xl: var(--ds-font-size-4xl);
      --ds-font-size-heading-2xl: var(--ds-font-size-5xl);
      --ds-font-size-heading-3xl: var(--ds-font-size-6xl);
    }
  }

  html[lang^='ar'] body[class^='theme-'] {
    --font-sans: 'Sans Arabic';
    --font-headline: 'Sans Arabic';
    --font-headline-brand: 'Massira Pen';
  }

  html[lang^='ko'] body[class^='theme-'] {
    --font-sans: 'Yd Gothic';
    --font-headline: 'Yd Gothic';
    --font-headline-brand: 'Nanum Pen';
  }

  html[lang^='th'] body[class^='theme-'] {
    --font-sans: 'Neue Frutiger';
    --font-headline: 'Neue Frutiger';
    --font-headline-brand: 'Neue Frutiger';
  }

  html[lang^='zh'] body[class^='theme-'] {
    --font-headline: 'Fz Chao Cu Hei';
  }

  html[lang^='ru'] body[class^='theme-'] {
    --font-sans: 'Proxima Nova Cyrillic';
  }
}
